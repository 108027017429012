<template>
  <v-container class="exam-schedule-container">
    <template>
      <v-row v-if="exam_schedules.length !== 0" class="table-header">
        <v-col cols="4">
          <div class="font-weight-black">Tên bài thi</div>
        </v-col>
        <v-col cols="3">
          <div class="font-weight-black">Thời gian thi</div>
        </v-col>
        <v-col cols="2">
          <div class="font-weight-black">Trạng thái</div>
        </v-col>
        <v-col cols="3"> </v-col>
      </v-row>
      <v-row v-if="exam_schedules.length === 0"
        ><v-col>
          <div>
            <v-alert :value="true" color="warning" icon="priority_high" outlined>
              Chưa có dữ liệu hiển thị
            </v-alert>
          </div>
        </v-col></v-row
      >
      <v-row
        class="my-2"
        style="border: 1px solid black; border-left: 10px solid green"
        v-for="(item, index) in exam_schedules"
        :key="index"
      >
        <v-col cols="4">
          <div>{{ item.title }}</div>
        </v-col>
        <v-col cols="3">
          {{ item.start_date_time }}
        </v-col>
        <v-col cols="2">
          <div v-html="getStatusSchedule(item.active)"></div>
        </v-col>
        <v-col cols="1" v-if="item.active != 2">
          <!-- schedule  -->
          <v-dialog v-model="dialogSchedule" :retain-focus="false" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :hidden="item.deleteActive"
                elevation="3"
                medium
                outlined
                raised
                @click="editItem(item)"
                class="btn-submit"
                style="background-color: black"
                >Sửa lịch
              </v-btn>
            </template>
            <v-card>
              <v-container>
                <v-row>
                  <v-col cols="5"></v-col>
                  <v-col cols="5">
                    <span class="title schel">ĐẶT LỊCH THI</span>
                  </v-col>
                  <v-col cols="2"></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2"></v-col>
                      <v-col cols="4">
                        <ValidationProvider
                          name="picker"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-date-picker v-model="picker"></v-date-picker>
                          <span class="validate-error text-danger text-danger">
                            {{ errors[0] }}
                          </span>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="4">
                        <v-time-picker v-model="timer" format="ampm"></v-time-picker>
                      </v-col>
                      <v-col cols="2"></v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <div class="validate-error text-danger text-center">{{ message }}</div>
                  </v-col>

                  <v-col cols="8"></v-col>
                  <v-col cols="4">
                    <v-btn large @click="dialogSchedule = false" class="btn-cancel">
                      Cancel
                    </v-btn>
                    <v-btn
                      large
                      @click="submitSchedule"
                      class="btn-submit"
                      style="background-color: black; color:white"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="2" v-if="item.active != 2">
          <v-dialog v-model="dialogDelete" :retain-focus="false" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :hidden="item.deleteActive"
                elevation="3"
                medium
                outlined
                raised
                @click="deleteItem(item)"
                class="btn-submit"
                style="background-color: black"
                >Xóa lịch
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline"
                >Bạn có chắc chắn muốn xóa lịch thi này?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Hủy</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm()">Xóa</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row v-if="exam_schedules.length>0">
        <div class="ml-10">
          <p class="red--text">Chú ý (*):</p>
          <p class="red--text"><i>Khi bài thi đang diễn ra không thể chỉnh sửa hoặc xóa lịch thi!</i></p>
        </div>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import {
  GET_EXAM_SCHEDULE_LIST,
  UPDATE_EXAM_SCHEDULE,
  DELETE_EXAM_SCHEDULE,
} from "@/store/exams.module";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  data() {
    return {
      picker: new Date().toISOString().substr(0, 10),
      timer: "11:15",
      //______________________
      dialog: false,
      dialogDelete: false,
      dialogSchedule: false,
      editedIndex: -1,
      editedItem: {},
      message:"",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    ...mapGetters({
      exam_schedules: "examScheduleList",
    }),
  },
  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Danh sách lịch thi",titledad:'Đề thi' }]);
    await this.$store.dispatch(GET_EXAM_SCHEDULE_LIST, {});
    if (this.exam_schedules){
      console.log(23233)
      console.log(this.exam_schedules)
    }
  },
  methods: {
    getStatusSchedule(status){
      if (status === 2){
        return '<span class="blue--text">Đang diễn ra </span>'
      }
      if (status !== 2){
        return '<span class="green--text">Sắp diễn ra</span>'
      }
    },
    submitSchedule() {

      // format day with time
      this.editedItem.start_date_time = this.picker + " " + this.timer;
      if (this.isPastSchedule(this.editedItem.start_date_time)) {
        this.message = "Bạn không thể đặt thời gian đã qua"
        return null;
      }
      this.$store
        .dispatch(UPDATE_EXAM_SCHEDULE, {
          exam_schedule: this.editedItem,
        })
        .then((data) => {
          if (data.data.status) {
            this.$store.dispatch(GET_EXAM_SCHEDULE_LIST, {});
            this.dialogSchedule = false;
          }
        });
    },
    setCalendar(date){
      var res = date.split(" ");
      if(res.length<=1){
        return null;
      }
      this.picker = res[0];
      this.timer = res[1];
    },
    editItem(item) {

      this.setCalendar(item.start_date_time)
      this.editedIndex = this.exam_schedules.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogSchedule = true;
    },
    deleteItem(item) {
      this.editedIndex = this.exam_schedules.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store
        .dispatch(DELETE_EXAM_SCHEDULE, {
          exam_schedule_id: this.editedItem.id,
        })
        .then((data) => {
          if (data.data.status) {
            this.$store.dispatch(GET_EXAM_SCHEDULE_LIST, {});
            this.dialogSchedule = false;
          }
        });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    // rule validate
    isPastSchedule(time) {
      let timeNow = new Date();
      return Date.parse(time) < timeNow
    }
  },
};
</script>

<style lang="scss" >
.btn-submit {
  background-color: black;
  color: white;
  border-radius: 10px;
}

.btn-cancel {
  border-radius: 10px;
}
.title {
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 29px;
  display: flex;
}
.schel {
  font-size: 40px;
}
.table-header {
  font-size: 1.2rem !important;
}

.exam-schedule-container {
  .theme--light.v-btn {
    color: white;
  }

}
</style>
